import config from "../config";
import history from "../common/history";

const onApiError = (e) => {
  throw e;
};

const onApiSuccess = (res) => res;

export const tokenStorage = (function () {
  const COOKIE_NAME = "_superfi_admin_token_";

  // Note: use local storage to store token whenever possible
  // if not found, downgrade to local variable, which will require users
  // to sign in after refreshing pages
  if (typeof localStorage !== "undefined") {
    return {
      get: () => {
        return localStorage.getItem(COOKIE_NAME);
      },
      set: (token) => {
        return localStorage.setItem(COOKIE_NAME, token);
      },
      remove: () => {
        return localStorage.removeItem(COOKIE_NAME);
      },
    };
  }
})();

const invokeApi = ({
  path,
  method = "GET",
  headers = {},
  authorization = false,
  isFile = false,
  body,
}) => {
  const apiUrl = config.API_URL + path;

  headers["x-api-key"] = config.API_KEY;

  if (authorization) {
    const token = tokenStorage.get();
    if (token && token.length && token !== "null") {
      headers.authorization = `Bearer ${token}`;
    }
  }
  if (!isFile && body) {
    body = body && JSON.stringify(body);
  }
  return fetch(apiUrl, {
    method,
    body,
    headers,
  }).then((results) => {
    if (results.status === 401 || results.status === 403) {
      logout();
      throw new Error("Unauthorized");
    }
    if (results.status !== 200) {
      return results.json().then((data) => {
        return Promise.reject(data);
      });
    }

    return results
      .json()
      .then((json) => {
        return Promise.resolve(json);
      })
      .catch((err) => Promise.reject(err));
  });
};

// Logout user from the admin pannel
export const logout = () => {
  tokenStorage.remove();
  history.push("/login");
};

// Get the user auth token
const processToken = (result) => {
  if (result.status) {
    tokenStorage.set(result.data.token);
  }
  return result;
};

// API for admin login
export const login = (data) =>
  invokeApi({
    method: "POST",
    path: "/admin/login",
    headers: { "Content-Type": "application/json" },
    body: data,
  })
    .then((res) => {
      return processToken(res);
    })
    .then(onApiSuccess, onApiError);

// API for forgot password
export const forgotPassword = (data) =>
  invokeApi({
    method: "POST",
    path: `/admin/forgot-password`,
    headers: { "Content-Type": "application/json" },
    body: data,
  }).then(onApiSuccess, onApiError);

// API for admin details
export const getAdminProfile = () =>
  invokeApi({
    method: "GET",
    path: `/admin/check`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

// API for dashboard data
export const getAdminDashboardStats = (partnerId) =>
  invokeApi({
    method: "GET",
    path: `/admin/dashboard/stats?id=${partnerId ? partnerId : null}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

// API for user list who are eligibale for the rewards
export const getCompleteTaskList = () =>
  invokeApi({
    method: "GET",
    path: `/admin/user/completed-reward`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

// API to send the reward to the user
export const sendRewardData = (data) =>
  invokeApi({
    method: "POST",
    path: `/admin/user/${data.id}/send-reward`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

// API for Get the list of the rewards
export const getRewardTaskList = () =>
  invokeApi({
    method: "GET",
    path: `/admin/rewards`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

// API for Update the pause/unpause data
export const updateNewSignupSettting = (data = {}) =>
  invokeApi({
    method: "POST",
    path: `/admin/dashboard/signup-action`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

// API for Update the cashback amount for the month
export const postCashbackAmount = (data = {}) =>
  invokeApi({
    method: "POST",
    path: `/admin/dashboard/reward-cashback`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

// API for Get the list of the app users
export const getAllUsers = () =>
  invokeApi({
    method: "GET",
    path: `/admin/users`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

// API for Update the user based on the action
export const postUserAction = (userId = null, data = {}) =>
  invokeApi({
    method: "POST",
    path: `/admin/user/${userId}/action`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

// API for get the user detail from the database
export const getUserInfo = (userId = null) =>
  invokeApi({
    method: "GET",
    path: `/admin/user/${userId}/view`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

// API for get the user detail from the database and truelayer
export const getUserLiveInfo = (userId = null) =>
  invokeApi({
    method: "GET",
    path: `/admin/user/${userId}/live/view`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

// API for get list of the card catelog
export const getCardCatalog = () =>
  invokeApi({
    method: "GET",
    path: `/admin/card-catalog`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

// API for update the card data based on the action
export const postCardCatalogInfo = (cardId = null, data = {}) =>
  invokeApi({
    method: "POST",
    path: `/admin/card-catalog/${cardId}/action`,
    authorization: true,
    isFile: true,
    body: data,
  }).then(onApiSuccess, onApiError);

// API for get all the admin user data
export const getAllAdmins = () =>
  invokeApi({
    method: "GET",
    path: `/admin/admins`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

// API for add new the admin user data
export const postNewAdmin = (data = {}) =>
  invokeApi({
    method: "POST",
    path: `/admin/admins/add`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

// API for update the admin user data
export const updateAdmin = (adminId = null, data = {}) =>
  invokeApi({
    method: "POST",
    path: `/admin/admins/${adminId}/update`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

// API for delete the admin user
export const deleteAdmin = (adminId = null) =>
  invokeApi({
    method: "POST",
    path: `/admin/admins/${adminId}/delete`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getMerchantIcons = () =>
  invokeApi({
    method: "GET",
    path: `/admin/merchant-icons`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const postMerchantIconInfo = (iconId = null, data = {}) =>
  invokeApi({
    method: "POST",
    path: `/admin/merchant-icons/${iconId}/action`,
    authorization: true,
    isFile: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const getMarketplaceClickInfo = (partnerId) =>
  invokeApi({
    method: "GET",
    path: `/admin/get-marketplace-click-info?id=${
      partnerId ? partnerId : null
    }`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getTags = () =>
  invokeApi({
    method: "GET",
    path: `/admin/get-tags`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getMerchantListing = () =>
  invokeApi({
    method: "GET",
    path: `/admin/get-merchant-listing`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getCouncilTaxes = (partnerId) =>
  invokeApi({
    method: "GET",
    path: `/admin/get-council-tax-info?id=${partnerId ? partnerId : null}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getGeneralTabReports = (partnerId) =>
  invokeApi({
    method: "GET",
    path: `/admin/get-general-tab-reports?id=${partnerId ? partnerId : null}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getFinancialTabReports = (partnerId) =>
  invokeApi({
    method: "GET",
    path: `/general/admin-report-start-month-cron?id=${
      partnerId ? partnerId : null
    }`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getPartnerOnboardingCodes = () =>
  invokeApi({
    method: "GET",
    path: `/admin/get-onboarding-partner-codes`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

// API for Updating the editable fields inside admin
export const updateAdminFields = (data = {}) =>
  invokeApi({
    method: "POST",
    path: `/admin/admin-update-manual-data`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const getConsumerDutyTabReports = (partnerId) =>
  invokeApi({
    method: "GET",
    path: `/admin/get-consumer-duty-reports?id=${partnerId ? partnerId : null}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const adminReportPdf = (data = {}) =>
  invokeApi({
    method: "POST",
    path: `/admin/export-report-pdf`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const getSpinTheWheelData = (data = {}) =>
  invokeApi({
    method: "GET",
    path: `/admin/get-spin-the-wheel-data`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getUserVoucherData = (data = {}) =>
  invokeApi({
    method: "GET",
    path: `/admin/get-user-voucher-data`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const updateUserVoucherInfo = (data = {}) =>
  invokeApi({
    method: "POST",
    path: `/admin/update-user-voucher-data`,
    authorization: true,
    isFile: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const updateSpinTheWheelInfo = (data) =>
  invokeApi({
    method: "POST",
    path: `/admin/update-spin-the-wheel-data`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const addMerchantData = (data = {}) =>
  invokeApi({
    method: "POST",
    path: `/admin/add-new-merchant-data`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const deleteMerchantData = (data = {}) =>
  invokeApi({
    method: "POST",
    path: `/admin/delete-merchant-data`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const updatePartnerAction = (data = {}) =>
  invokeApi({
    method: "POST",
    path: `/admin/update-partner-action`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const addEditPartner = (data = {}) =>
  invokeApi({
    method: "POST",
    path: `/admin/add-edit-partner-action`,
    authorization: true,
    body: data,
    isFile: true,
  }).then(onApiSuccess, onApiError);

export const getSubCodeList = (master_code) =>
  invokeApi({
    method: "GET",
    path: `/admin/get_subcodelist?partner_id=${master_code}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const deleteSubCode = (data = {}) =>
  invokeApi({
    method: "POST",
    path: `/admin/delete_subcode`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const addSubCode = (data = {}) =>
  invokeApi({
    method: "POST",
    path: `/admin/add_subcode`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    // isFile: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const getUsedSubCodeList = (master_code) =>
  invokeApi({
    method: "POST",
    path: `/admin/subcode_used_list`,
    headers: { "Content-Type": "application/json" },
    body: { master_code },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const deleteUsedSubCodeList = (data) =>
  invokeApi({
    method: "POST",
    path: `/admin/removeUserSubcode_used`,
    headers: { "Content-Type": "application/json" },
    body: data,
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const pauseUsedSubCodeList = (data) =>
  invokeApi({
    method: "POST",
    path: `/admin/pauseUserSubcode_used`,
    headers: { "Content-Type": "application/json" },
    body: data,
    authorization: true,
  }).then(onApiSuccess, onApiError);

//CASHBACK RETAILERS MODULES
export const getcashbackRetailerList = (data = {}) =>
  invokeApi({
    method: "GET",
    path: `/admin/cashback_retailerList`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const deleteRetailer = (data = {}) =>
  invokeApi({
    method: "POST",
    path: `/admin/delete_retailer`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const addUpdateRetailer = (data = {}) =>
  invokeApi({
    method: "POST",
    path: `/admin/addUpdate_retailer`,
    authorization: true,
    isFile: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const isactiveRetailer = (data = {}) =>
  invokeApi({
    method: "POST",
    path: `/admin/updateIsactive`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const tilloSyncAdminData = () =>
  invokeApi({
    method: "GET",
    path: `/user/superfi/tillo-data-sync`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);
